import { Button, Input } from '@/components'
import { Tabs, TabTriggersContainer, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs/Tabs'
import { SearchIcon } from 'lucide-react'
import { motion } from 'framer-motion'
import BirthdayEvent from './BirthdayEvent'
import { useAddQueryParamsToURL } from '@/hooks/useAddQueryParamsToURL'
import AnniversaryEvent from './AnniversaryEvent'
import FareWellEvent from './FarewellEvents'

const route_const = {
    birthday: 'birthday',
    farewells: 'farewells',
    anniversaries: 'anniversaries',
    event_type: 'event_type',
}
export default function Events() {
    const [setEventFilter, searchParamDetails] =
        useAddQueryParamsToURL<{ [event_type in typeof route_const.event_type]: '' }>()

    const subRoute = searchParamDetails.get(route_const.event_type) ?? 'birthday'

    return (
        <section>
            <div className='flex justify-between items-center mt-8'>
                <div className='mb-2 lg:mb-6'>
                    <h1 className='text-dark font-medium text-lg'>Events</h1>
                    <p className='text-gray7 text-sm'>See all upcoming and ongoing events</p>
                </div>
                <div className='flex gap-5 items-center'>
                    <Input
                        className='border-transparent bg-white shadow-sm h-12'
                        placeholder='Search by name'
                        leftIcon={<SearchIcon className='text-gray7' />}
                    />
                    <Button> Create event</Button>
                </div>
            </div>
            <div className='bg-white shadow-sm rounded-lg p-4 mt-8 mb-3'>
                <Tabs defaultValue={route_const.birthday} value={subRoute}>
                    <TabsList className={`border-none  flex justify-between items-center p-0 mb-6`}>
                        <TabTriggersContainer className=' lg:w-full'>
                            <TabsTrigger
                                value={route_const.birthday}
                                className={`relative border-none px-3 ${
                                    route_const.birthday === subRoute ? '!text-[#454ADE]' : ''
                                } text-sm`}
                                onClick={() => setEventFilter(route_const.event_type, route_const.birthday)}
                            >
                                {route_const.birthday === subRoute && (
                                    <motion.div
                                        layoutId='directory_btn'
                                        className={
                                            'bg-[#EFEFF9]  rounded-lg  text-sm  w-full left-[1px] top-1  bottom-1 absolute'
                                        }
                                    />
                                )}
                                <motion.span layout='position' className='z-20 relative'>
                                    Birthdays
                                </motion.span>
                            </TabsTrigger>

                            <TabsTrigger
                                value={route_const.anniversaries}
                                className={`relative border-none px-3 ${
                                    route_const.anniversaries === subRoute ? '!text-[#454ADE]' : ''
                                } text-sm`}
                                onClick={() => setEventFilter(route_const.event_type, route_const.anniversaries)}
                            >
                                {route_const.anniversaries === subRoute && (
                                    <motion.div
                                        layoutId='directory_btn'
                                        className={
                                            'bg-[#EFEFF9]  rounded-lg  text-sm  w-full left-[1px] top-1  bottom-1 absolute'
                                        }
                                    />
                                )}
                                <motion.span layout='position' className='z-20 relative'>
                                    Anniversaries
                                </motion.span>
                            </TabsTrigger>
                            <TabsTrigger
                                value={route_const.farewells}
                                className={`relative border-none px-3 ${
                                    route_const.farewells === subRoute ? '!text-[#454ADE]' : ''
                                } text-sm`}
                                onClick={() => setEventFilter(route_const.event_type, route_const.farewells)}
                            >
                                {route_const.farewells === subRoute && (
                                    <motion.div
                                        layoutId='directory_btn'
                                        className={
                                            'bg-[#EFEFF9]  rounded-lg  text-sm  w-full left-[1px] top-1  bottom-1 absolute'
                                        }
                                    />
                                )}
                                <motion.span layout='position' className='z-20 relative'>
                                    Farewells
                                </motion.span>
                            </TabsTrigger>
                        </TabTriggersContainer>
                    </TabsList>
                    <TabsContent value={route_const.birthday}>
                        <BirthdayEvent />
                    </TabsContent>
                    <TabsContent value={route_const.anniversaries}>
                        <AnniversaryEvent />
                    </TabsContent>
                    <TabsContent value={route_const.farewells}>
                        <FareWellEvent />
                    </TabsContent>
                </Tabs>
            </div>
        </section>
    )
}
