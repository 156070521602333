import { Button } from '@/components'
import './index.css'
import { useState } from 'react'
import BirthdayWishModal from '@/components/Modals/Events/BirthdayWishModal'

export default function BirthdayEvent() {
    return (
        <section className='grid grid-cols-3 2xl:grid-cols-4 gap-8'>
            <BirthdayCard active />
            <BirthdayCard active={false} />
            <BirthdayCard active />
        </section>
    )
}

type CardProps = {
    active: boolean
}
const BirthdayCard = ({ active }: CardProps) => {
    const [openModal, setOpenModal] = useState(false)
    return (
        <div className={active ? 'active-event-gradient-border' : 'inactive-event-gradient-border'}>
            <div className='flex justify-between items-center text-sm'>
                <div className='flex items-center gap-4'>
                    <span className='overflow-hidden rounded-full h-8 w-8 inline-block'>
                        <img
                            className='w-full h-full'
                            src='https://bizpend-users.s3.eu-west-2.amazonaws.com/1699578618711profile_dp.png'
                            alt='Birthday image'
                        />
                    </span>
                    <div>
                        <h2 className='font-medium'>Rebecca Akinsanya</h2>
                        <p className='text-gray7'>June 15, 2024</p>
                    </div>
                </div>
                {active ? (
                    <span className='bg-white rounded-full p-1 px-3 text-brand text-xs'>Today</span>
                ) : (
                    <span className='bg-brand/5 rounded-full p-1 px-3 text-brand text-xs'>in 5 days</span>
                )}
            </div>
            <Button
                onClick={() => setOpenModal(true)}
                className={`min-w-full mt-8 rounded-lg min-h-[45px] ${active ? '' : 'bg-white border border-gray3 text-black hover:bg-transparent'}`}
            >
                🎊 {active ? 'Send' : 'Schedule'} Birthday Wish
            </Button>

            {openModal && <BirthdayWishModal setShowModal={setOpenModal} />}
        </div>
    )
}
