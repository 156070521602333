import { Button } from '@/components/Button'
import { LucideEdit, Plus, Save, Trash } from 'lucide-react'
import React, { useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Input } from '@/components/Input'

const tableHead = ['Holiday name', 'Date', 'Type', '']

type HolidayFormType = {
    date: string
    name: string
    type: 'recurring' | 'one_off'
}

const HolidaySettings = () => {
    const [editRowIndex, setEditRowIndex] = useState<number>()
    const form = useForm<{
        holiday: HolidayFormType[]
    }>({
        defaultValues: {
            holiday: [{ name: '', date: '', type: 'recurring' }],
        },
    })
    const { fields, append, remove, update } = useFieldArray({
        name: 'holiday',
        control: form.control,
    })

    return (
        <div className='border border-gray5 px-3 lg:px-5 p-5 rounded-lg mt-5 w-full'>
            <div className='flex justify-between mb-8'>
                <div>
                    <h2 className='text-lg font-medium'>Holiday settings</h2>
                    <p className='text-[14px] text-[#5E5E5E]  mt-1'>
                        Define paid holidays for automatic payroll adjustment
                    </p>
                </div>

                <button
                    title='action'
                    className='inline-flex items-center bg-[#EFEFF9] text-[#454ADE] text-sm rounded-md gap-1 px-5 py-1 h-12 whitespace-nowrap'
                    onClick={() => append({ date: '', name: '', type: 'recurring' })}
                >
                    <Plus size={17} />
                    Add new
                </button>
            </div>
            <div className='w-full overflow-x-scroll'>
                <table className='border-separate border-spacing-x-0'>
                    <thead className='bg-light-primary [&_tr:first-child]:rounded-md '>
                        <tr className='[&_tr:first-child]:rounded-md'>
                            {tableHead.map(head => (
                                <th
                                    key={head}
                                    className={
                                        '[&:first-child]:rounded-tl-xl border-y [&:last-child]:border-r [&:first-child]:border-l  [&:last-child]:rounded-tr-xl h-12 border-gray5 pl-4 text-left font-semibold text-sm w-36 &:last-child]:w-auto'
                                    }
                                >
                                    {head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((data, idx) => (
                            <tr className='hover:bg-transparent' key={idx}>
                                <td
                                    className={`${fields.length === idx + 1 ? 'rounded-bl-xl border-t-0' : ''} ${fields.length !== idx + 1 ? 'border-b' : 'border-y'} px-3 h-20  text-gray-600  border-gray5 border-l `}
                                >
                                    {fields.length === idx + 1}
                                    {editRowIndex === idx ? (
                                        <Controller
                                            control={form.control}
                                            name={`holiday.${idx}.name`}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    className='min-w-[120px] h-12 border-gray5'
                                                    onChange={field.onChange}
                                                />
                                            )}
                                        />
                                    ) : (
                                        data?.name
                                    )}
                                </td>
                                <td
                                    className={`${fields.length !== idx + 1 ? 'border-b' : 'border-y'} ${fields.length === idx + 1 ? 'border-t-0' : ''} h-20 px-3  py-3  text-gray-600  border-gray5`}
                                >
                                    {editRowIndex === idx ? (
                                        <Controller
                                            control={form.control}
                                            name={`holiday.${idx}.date`}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    className='min-w-[120px] h-12 border-gray5'
                                                    onChange={field.onChange}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <span>{data?.date}</span>
                                    )}
                                </td>
                                <td
                                    className={`${fields.length !== idx + 1 ? 'border-b' : 'border-y'} ${fields.length === idx + 1 ? 'border-t-0' : ''} h-20 px-3  py-3  text-gray-600   border-gray5`}
                                >
                                    {editRowIndex === idx ? (
                                        <Controller
                                            control={form.control}
                                            name={`holiday.${idx}.date`}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    className='min-w-[120px] h-12 border-gray5'
                                                    onChange={field.onChange}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <span>{data?.date}</span>
                                    )}
                                </td>

                                <td
                                    className={`${fields.length !== idx + 1 ? 'border-b' : 'border-y'} h-20 flex justify-end gap-4 p-3  py-3.5  text-gray-600 border-r border-gray5 ${fields.length === idx + 1 ? 'rounded-br-xl border-t-0' : ''}`}
                                >
                                    {editRowIndex !== idx ? (
                                        <Button
                                            className='border-gray5 text-xs gap-2 rounded-md px-3'
                                            size={'sm'}
                                            variant={'outline'}
                                            onClick={() => {
                                                setEditRowIndex(idx)
                                                update(idx, data)
                                            }}
                                        >
                                            Edit <LucideEdit size={12} />
                                        </Button>
                                    ) : (
                                        <Button
                                            className='border-gray5 text-xs gap-2 rounded-md px-3'
                                            size={'sm'}
                                            onClick={() => setEditRowIndex(undefined)}
                                        >
                                            Save <Save size={12} />
                                        </Button>
                                    )}
                                    <button
                                        title='Icon'
                                        className='aspect-square rounded bg-red-100 p-1 inline-flex items-center justify-center'
                                        onClick={() => remove(idx)}
                                    >
                                        <Trash className='text-red-500' size={15} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default HolidaySettings
