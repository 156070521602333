import { Controller, useForm } from 'react-hook-form'
import ModalWrapper from '../ModalWrapper'
import DatePicker from 'react-datepicker'
import { AiOutlineClose } from 'react-icons/ai'
import { Button } from '@/components/Button'
import { Textarea } from '@/components/Textarea'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { useRef } from 'react'

type FormProps = {
    message: string
    date?: Date
    time?: string
}

type Props = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultBirthdayText =
    'On behalf of everyone at [Company Name], I would like to wish you a very Happy Birthday! 🎉 We hope your special day is filled with joy, laughter, and wonderful memories. Your hard work, dedication, and positive attitude make a significant impact on our team, and we are grateful to have you as part of the [Company Name] family. May this year bring you success, happiness, and the fulfillment of all your dreams. Enjoy your day to the fullest!'

export default function BirthdayWishModal({ setShowModal }: Props) {
    const calendarRef1 = useRef<DatePicker>(null)

    const {
        handleSubmit,
        control,

        formState: { isValid },
    } = useForm<FormProps>({
        defaultValues: {
            message: defaultBirthdayText,
        },
    })
    const OnSubmit = (values: FormProps) => {
        //
        console.warn(values)
    }
    return (
        <ModalWrapper>
            <section className='w-screen lg:w-[40rem] min-h-[69vh] max-h-[90vh] overflow-scroll relative bg-[#F9F9F9] rounded-lg'>
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <div className='rounded-t-lg z-50 overflow-hidden bg-gradient-to-tr from-[#1E378F] to-[#A185C3] text-white flex justify-between px-8 h-36 pt-8'>
                        <h2 className='font-semibold text-xl'>Send birthday wish</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => setShowModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                    <div className='flex justify-start items-center flex-col relative -top-16'>
                        <span className='overflow-hidden rounded-full h-32 w-3h-32 inline-block border border-gray5'>
                            <img
                                className='w-full h-full'
                                src='https://bizpend-users.s3.eu-west-2.amazonaws.com/1699578618711profile_dp.png'
                                alt='Birthday image'
                            />
                        </span>
                        <div>
                            <h2 className='font-medium text-lg'>Rebecca Akinsanya</h2>
                            <p className='text-gray7 text-sm text-center'>
                                June 15, 2024 <span className='text-brand text-xs'>(in 5 days)</span>
                            </p>
                        </div>
                    </div>

                    <section className='mx-6 mb-16 relative -top-8'>
                        <div className='flex justify-between gap-5 mb-2'>
                            <fieldset className='mb-[15px] flex flex-col flex-1  gap-1'>
                                <label className='text-sm mb-2' htmlFor='message'>
                                    Date
                                </label>
                                <Controller
                                    name='date'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <CustomDatePicker
                                            ref={calendarRef1}
                                            placeholderText='DD/MM/YYYY'
                                            onChange={date => field.onChange(date)}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode='select'
                                            selected={field.value ? new Date(field.value) : undefined}
                                        />
                                    )}
                                />
                            </fieldset>
                            <fieldset className='mb-[15px] flex flex-col flex-1  gap-1'>
                                <label className='text-sm mb-2' htmlFor='message'>
                                    Time
                                </label>
                                <Controller
                                    name='time'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <CustomDatePicker
                                            ref={calendarRef1}
                                            placeholderText='DD/MM/YYYY'
                                            onChange={date => field.onChange(date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption='Time'
                                            dateFormat='h:mm aa'
                                            selected={field.value ? new Date(field.value) : undefined}
                                        />
                                    )}
                                />
                            </fieldset>
                        </div>
                        <fieldset className='mb-[15px] flex flex-col flex-1  gap-1'>
                            <label className='text-sm mb-2' htmlFor='message'>
                                Birthday message
                            </label>
                            <Controller
                                name='message'
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Textarea {...field} className='resize-none text-gray7 border-gray2 h-36' />
                                )}
                            />
                        </fieldset>
                    </section>

                    <div className='bg-white fixed bottom-0 border-t border-gray8 w-full flex items-center justify-end gap-4  px-6 py-2 rounded-b-md'>
                        <Button
                            type={'submit'}
                            className='bg-[#454ADE] text-white px-8 h-[3rem] rounded-lg text-[14px] min-w-[130px]'
                            disabled={!isValid}
                        >
                            Send birthday message
                        </Button>
                    </div>
                </form>
            </section>
        </ModalWrapper>
    )
}
