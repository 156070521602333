import { Button } from '@/components'
import './index.css'
import { useState } from 'react'
import BirthdayWishModal from '@/components/Modals/Events/BirthdayWishModal'

export default function AnniversaryEvent() {
    return (
        <section className='grid grid-cols-3 2xl:grid-cols-4 gap-8'>
            <AnniversaryEventCard active />
            <AnniversaryEventCard active />
            <AnniversaryEventCard active />
            <AnniversaryEventCard active={false} />
            <AnniversaryEventCard active={false} />
        </section>
    )
}

type CardProps = {
    active: boolean
}
const AnniversaryEventCard = ({ active }: CardProps) => {
    const [openModal, setOpenModal] = useState(false)
    return (
        <div className={active ? 'active-event-gradient-border' : 'inactive-event-gradient-border'}>
            <div className='flex flex-col justify-between items-center text-sm'>
                <div className='flex flex-col  items-center '>
                    <span className='overflow-hidden rounded-full h-24 w-24 inline-block'>
                        <img
                            className='w-full h-full'
                            src='https://bizpend-users.s3.eu-west-2.amazonaws.com/1699578618711profile_dp.png'
                            alt='Birthday image'
                        />
                    </span>
                    <h2 className='font-medium'>Rebecca Akinsanya</h2>
                    <div className='flex items-center gap-2 mt-1'>
                        <p className='text-gray7 text-xs'>June 15, 2024</p>
                        {active ? (
                            <span className='bg-white rounded-full p-0.5 px-2 text-brand text-xs'>Today</span>
                        ) : (
                            <span className='bg-brand/5 rounded-full p-0.5 px-2 text-brand text-xs'>in 5 days</span>
                        )}
                    </div>
                </div>
            </div>
            <Button
                onClick={() => setOpenModal(true)}
                className={`min-w-full mt-8 rounded-lg min-h-[45px] ${active ? '' : 'bg-white border border-gray3 text-black hover:bg-transparent'}`}
            >
                🥂 Celebrate
            </Button>

            {openModal && <BirthdayWishModal setShowModal={setOpenModal} />}
        </div>
    )
}
